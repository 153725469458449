<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="getDyAuthUrl">新增授权</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键"
                width="120">
            </el-table-column>
            <el-table-column
                prop="userId"
                label="用户ID"
                width="120">
            </el-table-column>
            <el-table-column
                prop="openId"
                label="openId">
            </el-table-column>
            <el-table-column
                prop="nickname"
                label="昵称"
                width="120">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="修改时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.updateTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="avatar"
                label="头像">
                <template slot-scope='scope'>
                  <img :src="scope.row.avatar" width="50%" height="50%"/>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { getOpenPage } from '@/service/dyUser';
import { getDyAuthUrl, dyAuth } from '@/service/user';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getOpenPage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    getDyAuthUrl() {
      getDyAuthUrl({}).then(response => {
        if (response.code === 0) {
          let url = response.data;
          const newOpenWindow = window.open('about:blank');
          newOpenWindow.location = url;
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    },
    dyAuth(code){
      dyAuth({code}).then(response => {
        if (response.code === 0) {
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) {
        context = decodeURIComponent(r[2]);
      }
      reg = null;
      r = null;
      return context === null || context === "" || context === "undefined" ? "" : context;
    }
  }
};
</script>