import request from '@/service/lib/request';
// 抖音用户

/**
 * 分页
 * @author lb
 */
export function getOpenPage(data) {
    return request({
      url: '/dy_open_info/get_open_info_list',
      method: 'post',
      data
    });
}

/**
 * 获取当前登录人抖音用户信息
 * @author lb
 */
export function getOpenInfoByUser(data) {
  return request({
    url: '/dy_open_info/get_open_info_by_user',
    method: 'post',
    data
  });
}